import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col, CardDeck, Card, Accordion } from "react-bootstrap"
import SignUpComponent from "../../components/sign-up"

const EventHackathonPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        location={location}
        image="/images/pocam-white-bg.png"
        title="Beyond tropes, tokenism and talk"
        description="Beyond tropes, tokenism and talk Reimagining the strategist&#8217;s toolkit to be more inclusive of the BIPOC community Presented By: Tickets on sale now We are inviting agency strategists, researchers, creatives, media, account folks, etc to join us in a hackathon jointly organized by APG and POCAM to reimagine the planners toolkit &#8211; from the creative,&hellip;"
      />
      <section className="bg-dark text-white py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="8">
              <h1 className="display-4 mb-3">Beyond tropes, tokenism and talk</h1>
              <h2 className="mb-0">
                Reimagining the strategist’s toolkit to be more inclusive of the BIPOC community
              </h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="10">
              <p className="lead">
                Presented By:
              </p>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md="3">
              <img className="px-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/APG-Canada-Logo-RGB.svg'} alt="AGP Canada logo"/>
            </Col>
            <Col md="3">
              <img className="px-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/pocam-logo-red.png'} alt="POCAM logo"/>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="8">
              <img className="" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/banner.png'} alt="banner"/>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="10">
              <p>
                Tickets on sale now
              </p>
              <p>
                We are inviting agency strategists, researchers, creatives, media, account folks, etc to join us in a hackathon jointly organized by <a href="https://www.apgcanada.ca/" rel="noreferrer" target="_blank">APG</a> and <a href="https://www.wearepocam.ca/">POCAM</a> to reimagine the planners toolkit - from the creative, to the research, to the media brief.
              </p>
              <p>
                In order to better ensure more seamless integration of a consumer, culture and lifestyle that is reflective of Canada’s dynamically changing population, we need to reimagine the strategy fundamentals.
              </p>
              <p>
                If you would like to attend the event, to see what more inclusive briefs could look like, purchase your ticket below. Please note, applications for this initiative have now closed. All applicants will be notified of their status by Wed, April 10th.
              </p>

              <a
                className="btn btn-primary btn-lg mt-3"
                rel="noreferrer"
                href="https://www.apgcanada.ca/event-calendar/#id=162&cid=1396&wid=201"
                target="_blank"
              >
                Purchase event ticket
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-dark text-white py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="8">
              <h3>The Streams</h3>
              <p>Participants will be asked to re-imagine the creative, research or media brief to be more inclusive based on thoughtstarters such as those identified below.</p>
            </Col>
            <Col md="12">
              <div className="mt-4 mb-4">
                <hr style={{ borderColor: "#fff" }}/>
              </div>
            </Col>
            <Col md="10">
              <h4>Creative brief:</h4>
              <ul>
                <li>What biases and assumptions are baked into the brief?</li>
                <li>How should we rethink the target audience and insight sections?</li>
                <li>What executional mandatories might we need to consider?</li>
              </ul>

              <h4>Research brief:</h4>
              <ul>
                <li>How are we recruiting respondents?</li>
                <li>Do we need to reconsider how we word certain questions?</li>
                <li>What methodologies are we employing? Are they inclusive enough?</li>
                <li>Who is doing the analysis? How are we accounting for biases?</li>
              </ul>

              <h4>Media brief:</h4>
              <ul>
                <li>When building consumer personas, what stereotypes do we need to set aside?</li>
                <li>How can we acknowledge wildly diverse segments (within segments)?</li>
                <li>How can we better account for ethnic media consumption channels?</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="8">
              <h3>The Event</h3>
              <p>Each team will present their thoughts at a ticketed event to a live audience and panel of judges. The event will be held on April 24th from 5-8pm at George Brown Lakeshore Campus, 51 Dockside Dr. Toronto.</p>
              <p>
                <a href="https://www.apgcanada.ca/event-calendar/#id=162&cid=1396&wid=201" rel="noreferrer" target="_blank">Get your ticket</a> to this historic event today.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>The MCs</h4>
              <p>The hosts for the event</p>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col>
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/justin-senior.jpeg'} alt="Justin Senior"/>
                    <Card.Title className="mb-0">Justin Senior</Card.Title>
                    <Card.Text>Head of Media + Creative<br/>RISE Integrated Sports + Entertainment</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/justin-senior-8b77412a/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/michelle-lee.png'} alt="Michelle Lee"/>
                    <Card.Title className="mb-0">Michelle Lee</Card.Title>
                    <Card.Text>Executive Board Member<br/>APG</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/michelleilee/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4" id="mentors">
            <Col md="8">
              <h4>The Mentors</h4>
              <p>Subject matter experts responsible for counselling the teams and helping to shape the final presentation.</p>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col>
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/gavin-barrett.png'} alt="Gavin Barrett"/>
                    <Card.Title className="mb-0">Gavin Barrett</Card.Title>
                    <Card.Text>CEO and CCO<br/>Barrett & Welsh</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/gavinbarrettbw/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/sabaa-quao.png'} alt="Sabaa Quao"/>
                    <Card.Title className="mb-0">Sabaa Quao</Card.Title>
                    <Card.Text>CCO<br/>Cossette</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/sabaa/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/kathy-cheng.jpeg'} alt="Kathy Cheng"/>
                    <Card.Title className="mb-0">Kathy Cheng</Card.Title>
                    <Card.Text>Founder & CEO<br/>inca</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/kathycheng/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/robin-brown.png'} alt="Robin Brown"/>
                    <Card.Title className="mb-0">Robin Brown</Card.Title>
                    <Card.Text>EVP<br/>Dig Insights</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/robin-brown-a729a52/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/susan-aniche.jpeg'} alt="Susan Aniche"/>
                    <Card.Title className="mb-0">Susan Aniche</Card.Title>
                    <Card.Text>Strategy Director<br/>EssenceMediacom</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://linkedin.com/in/susananiche/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/shane-cameron.jpeg'} alt="Shane Cameron"/>
                    <Card.Title className="mb-0">Shane Cameron</Card.Title>
                    <Card.Text>CSO<br/>Omnicom Media Group</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/shane-cameron-5991802/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>The Judges</h4>
              <p>Responsible for helping to build on and evaluate the initial ideas/brief blueprints, including awarding the team that has demonstrated the most progress in advancing the conversation around DEI through their reimagined brief.</p>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col>
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/aleena-mazhar.png'} alt="Aleena Mazhar Kuzma"/>
                    <Card.Title className="mb-0">Aleena Mazhar Kuzma</Card.Title>
                    <Card.Text>SVP Managing Director & Partner<br/>FUSE Create</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/aleenamazhar/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/waleed-nesyif.jpeg'} alt="Waleed Nesyif"/>
                    <Card.Title className="mb-0">Waleed Nesyif</Card.Title>
                    <Card.Text>Head of Partnerships & Revenue<br/>Cashew</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/waleed-nesyif-406b613a/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/jessica-borges.png'} alt="Jessica Borges"/>
                    <Card.Title className="mb-0">Jessica Borges</Card.Title>
                    <Card.Text>Group VP Business Lead<br/>Visionnaire</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/jessicaborges/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/seth-waterman.jpeg'} alt="Seth Waterman"/>
                    <Card.Title className="mb-0">Seth Waterman</Card.Title>
                    <Card.Text>Managing Director<br/>VML</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/swaterman/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/ted-doering.jpeg'} alt="Ted Doering"/>
                    <Card.Title className="mb-0">Ted Doering</Card.Title>
                    <Card.Text>SVP and Service Line Lead for Creative Excellence<br/>Ipsos</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/ted-doering-70ba8654/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/glenn-dsouza.jpeg'} alt="Glenn D'Souza"/>
                    <Card.Title className="mb-0">Glenn D'Souza</Card.Title>
                    <Card.Text>Head of Creative<br/>Forsman & Bodenfors</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/glen-d-souza-8a96542b/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/penelope-chow.jpeg'} alt="Penelope Chow"/>
                    <Card.Title className="mb-0">Penelope Chow</Card.Title>
                    <Card.Text>Managing Director<br/>UWG Monsoon</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/penelopechow/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/natalie-bomberry.jpeg'} alt="Natalie Bomberry"/>
                    <Card.Title className="mb-0">Natalie Bomberry</Card.Title>
                    <Card.Text>VP Operations<br/>Pilot PMR</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/natalie-bomberry/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/danny-smith.jpeg'} alt="Danny Smith"/>
                    <Card.Title className="mb-0">Danny Smith</Card.Title>
                    <Card.Text>Faculty<br/>George Brown</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/professordannysmith/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>The Participants</h4>
              <p>Responsible for re-imagining the briefs and presenting their 2.0 blueprints to a live audience on April 24th</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>Creative Team #1</h4>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col className="pb-5">
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/andy-ng.jpeg'} alt="Andy Ng"/>
                    <Card.Title className="mb-0">Andy Ng</Card.Title>
                    <Card.Text>Creative Director<br/>Cossette</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/andyphng/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/rami-dudin.jpeg'} alt="Rami Dudin"/>
                    <Card.Title className="mb-0">Rami Dudin</Card.Title>
                    <Card.Text>Group Strategy Director<br/>Courage</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/rami-dudin-473307a2/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/manasi-damle.jpeg'} alt="Manasi Damle"/>
                    <Card.Title className="mb-0">Manasi Damle</Card.Title>
                    <Card.Text>Marketing Manager<br/>Intellaquest</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/manasidamle1/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/mrigini-iyer.jpeg'} alt="Mrigini Iyer"/>
                    <Card.Title className="mb-0">Mrigini Iyer</Card.Title>
                    <Card.Text>Senior Account & Development Manager<br/>Independent</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/mriginiiyer/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/ibukun-adeleye.jpeg'} alt="Ibukun Adeleye"/>
                    <Card.Title className="mb-0">Ibukun Adeleye</Card.Title>
                    <Card.Text>Strategist, Brand & Analytics<br/>Saatchi & Saatchi</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/ibukunadeleye1/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>Creative Team #2</h4>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col className="pb-5">
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/charchit-kant.jpeg'} alt="Charchit Kant"/>
                    <Card.Title className="mb-0">Charchit Kant</Card.Title>
                    <Card.Text>Art Director & Photographer<br/>Independent</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/charchitkant/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/aaron-arellano.jpeg'} alt="Aaron Arellano"/>
                    <Card.Title className="mb-0">Aaron Arellano</Card.Title>
                    <Card.Text>Group Strategy Director<br/>Dentsu</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/aaronarellano/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/divya-haridass.jpeg'} alt="Divya Haridass"/>
                    <Card.Title className="mb-0">Divya Haridass</Card.Title>
                    <Card.Text>Senior Account Manager<br/>Ipsos</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/divyaharidass/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/ifeayolu-hyacinth.jpeg'} alt="Ifeayolu Hyacinth"/>
                    <Card.Title className="mb-0">Ifeayolu Hyacinth</Card.Title>
                    <Card.Text>Product Marketing Manager<br/>PayForce by FairMoney</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/ifeayolu/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/anayasha-dalia.jpeg'} alt="Anayasha Dalia"/>
                    <Card.Title className="mb-0">Anayasha Dalia</Card.Title>
                    <Card.Text>Social Media Expert<br/>One Hand Clap</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/anayasha-dalia-7693a0101/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>Media Team</h4>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col className="pb-5">
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/karina-black.jpeg'} alt="Karina Black"/>
                    <Card.Title className="mb-0">Karina Black</Card.Title>
                    <Card.Text>Associate Director<br/>Touche</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/blackkarina/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/nassabe-el-eter.jpeg'} alt="Nassab El Eter"/>
                    <Card.Title className="mb-0">Nassab El Eter</Card.Title>
                    <Card.Text>Senior Digital Media Lead<br/>Believeco:</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/nassab-el-eter-mba-71380072/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/bianca-chanda.jpeg'} alt="Bianca Chanda"/>
                    <Card.Title className="mb-0">Bianca Chanda</Card.Title>
                    <Card.Text>Teaching Assistant<br/>Armstrong Acting Studios</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/biancachanda/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/rachel-won.jpeg'} alt="Rachel Won"/>
                    <Card.Title className="mb-0">Rachel Won</Card.Title>
                    <Card.Text>Market Research Project Management Analyst<br/>Ipsos</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/rachel-j-won/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/syona-abdeen.jpeg'} alt="Syona Abdeen"/>
                    <Card.Title className="mb-0">Syona Abdeen</Card.Title>
                    <Card.Text>Specialist, Business Development & Marketing<br/>EssenceMediacom</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/syona-linthorst-abdeen-a5256a18b/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h4>Research Team</h4>
            </Col>
          </Row>

          <Row className="row-deck">
            <Col>
              <CardDeck className="cd-10 cd-xl-5">
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/anastasia-tubanos.jpeg'} alt="Anastasia Tubanos"/>
                    <Card.Title className="mb-0">Anastasia Tubanos</Card.Title>
                    <Card.Text>Fractional VP Strategist<br/>T1</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/anastasiatubanos/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/grace-tong.jpeg'} alt="Grace Tong"/>
                    <Card.Title className="mb-0">Grace Tong</Card.Title>
                    <Card.Text>VP and EDI Practice Lead<br/>Ipsos</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/grace-tong-ipsos2570/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
                                
                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/emma-cunningham.jpeg'} alt="Emma Cunningham"/>
                    <Card.Title className="mb-0">Emma Cunningham</Card.Title>
                    <Card.Text>Professor<br/>Seneca College</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/cunninghamemma/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/nupur-gokhale.jpeg'} alt="Nupur Gokhale"/>
                    <Card.Title className="mb-0">Nupur Gokhale</Card.Title>
                    <Card.Text>Data Science Lead<br/>Cossette</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/nupurgokhale/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>

                <Card className="mt-5 shadow-1">
                  <Card.Body>
                    <img className="mb-4-up mb-3" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/farida-badrawy.jpeg'} alt="Farida Badrawy"/>
                    <Card.Title className="mb-0">Farida Badrawy</Card.Title>
                    <Card.Text>Strategist<br/>Independent</Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <ul className="list-inline mb-0">
                      <li>
                        <a href="https://www.linkedin.com/in/farida-badrawy-68aa0b141/" target="_blank" rel="noreferrer">
                          LinkedIn
                        </a>
                      </li>
                    </ul>
                  </Card.Footer>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <h3>FAQs</h3>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="8">
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    Who can participate in the hackathon?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>If you are currently working, have previously worked at, or are looking to join an agency as a strategist, researcher, creative, media, or account executive, etc. you are welcome to apply. All applicants must be signatories of the <a href="/call-for-equity/">POCAM Call for Equity</a>.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    What do I need to do to apply to participate?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>Applications for participation have now closed. All applicants will be notified of their status by April 10th.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    How will participants be chosen?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>Participants will be chosen by POCAM and APG to ensure a cross functional team (ie strategist, researcher, creative, media, account folks, etc.) in each stream.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                    Why are you doing this?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>The creative brief, research and media frameworks are fundamental tools of the strategic planners toolkit and we believe there is an opportunity to reimagine them to ensure they are more inclusive and reflective of Canada’s changing population ultimately to ensure that these conversations take place further upstream at the strategy level.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="4">
                    Who is involved?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>This hackathon is being jointly organized by <a href="https://www.apgcanada.ca/" rel="noreferrer" target="_blank">APG</a> and <a href="https://www.wearepocam.ca/">POCAM</a>. Sponsors include: <a href="https://www.thetradedesk.com/us" rel="noreferrer" target="_blank">The Trade Desk</a>, <a href="https://omnicommediagroup.com/" rel="noreferrer" target="_blank">Omnicom Media Group</a>, <a href="https://www.linkedin.com/company/visionnaire-futures/?originalSubdomain=ca" rel="noreferrer" target="_blank">Visionnaire</a>, <a href="https://www.wpp.com/" rel="noreferrer" target="_blank">WPP</a>, <a href="https://www.georgebrown.ca/" rel="noreferrer" target="_blank">George Brown</a>, <a href="https://www.ipgmediabrands.com/" rel="noreferrer" target="_blank">IPG Mediabrands</a>, <a href="https://www.publicisgroupe.com/fr/splash" rel="noreferrer" target="_blank">Publicis Groupe</a>, <a href="https://adaptmedia.com/" rel="noreferrer" target="_blank">Adapt Media</a>, <a href="https://believecopartners.com/" rel="noreferrer" target="_blank">BelieveCo: Partners</a>, <a href="https://www.barrettandwelsh.com/" rel="noreferrer" target="_blank">Barrett and Welsh</a>, <a href="https://fusecreate.com/" rel="noreferrer" target="_blank">FUSE Create</a>, <a href="https://www.risesportsent.com/" rel="noreferrer" target="_blank">RISE Integrated Sports + Entertainment</a></Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="5">
                    If I am chosen as a participant, what will I have to do?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>You will work with 4 other participants in one of three streams (i.e. remaining the creative brief, consumer research or media frameworks). You will be expected to attend a virtual session where you and your team will be briefed on your challenge. You will have two weeks to prepare your thoughts and your team will have the opportunity to meet with your assigned mentor on your presentation during this time. Then on April 24, 2024, from 5-8pm, you will present your thoughts to the judges and a ticketed audience at George Brown.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="6">
                    Why are you only focusing on BIPOC diversity vs other forms of diversity?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>DEI is obviously a massive topic. In order to make some practical headway, we would like to start by asking ourselves how we can re-imagine the strategist’s toolkit from the creative brief to research to media frameworks, specifically through the lens of BIPOC inclusivity. This is not to say that other groups and equally important issues (i.e. the LGBTQ+ community, ageism, physical abilities, etc) will not be addressed at later stages.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="7">
                    Who are the judges and what will we be judged on?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>The Advisory Team are senior leaders working in the industry representing a number of different agencies and disciplines. Winners will be awarded based on the team that has demonstrated the most progress in advancing the conversation around DEI through their reimagined brief.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="8">
                    Who are the mentors and what is their role?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>Each stream will have access to two <a href="#mentors">mentors</a> that are experts in their field whether it be the creative brief, research or media. They are there to provide guidance and builds on your early thinking before the presentation.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="9">
                    Is there a prize or compensation for participation?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      <p>All participants will be compensated for their time by having access to industry leaders in creative, strategy, media and research.</p>
                      <p>The winning team (or the team that has demonstrated the most progress in advancing the conversation around DEI through their reimagined brief) will also be eligible to receive a prize.</p>
                      <p>Contest Rules and Regulations </p>
                      <ol>
                        <li>No purchase necessary</li>
                        <li>As organizers and “assignees,” Account Planning Group of Canada (APG) and People of Colour in Advertising and Marketing (POCAM), have the right to cancel or suspend the contest, or modify the rules.</li>
                        <li>Participant’s teams’ submissions are reviewed by a panel of judges and a singular winning team will be selected by this same panel of judges.</li>
                        <li>The winning team will be based on the participants that have demonstrated the most progress in advancing the conversation around DEI through their reimagined brief.</li>
                        <li>The prize consists of a cash amount which to be shared evenly by the winning team, across all participants.</li>
                        <li>It is the individual winner's responsibility to understand and abide by any federal, provincial, territorial, local or foreign tax laws that may apply to receipt of a Prize.</li>
                        <li>The contest result and the winning team will be announced at the event on April 24, 2024 at George Brown, Lakeshore Campus, 51 Dockside Drive, Toronto, Ontario.</li>
                      </ol>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="10">
                    What happens after the event?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>A smaller task force will take the re-imagined briefs, implement the feedback provided by the judges, and upload the templates to the APG and POCAM websites as resources for the broader industry.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="11">
                    Can I purchase tickets to this event?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>Yes, tickets are available for purchase <a href="https://beeid.org/d328097a0dcd" rel="noreferrer" target="_blank">here</a> now.</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="mt-5 mb-5">
                <hr/>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              <h3>Sponsors</h3>
              <p>We would like to thank The Trade Desk, Omnicom Media Group, Visionnaire, IPG Mediabrands, WPP, George Brown, Publicis Groupe, Media Adapt Media, Believeco: Partners, Barrett and Welsh, FUSE Create, and RISE Integrated Sports + Entertainment for helping to make this initiative possible.</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="12">
              <img className="" style={{ width: "100%" }} src={'/images/beyond-tropes-tokenism-and-talk/sponsors.png'} alt="sponsors"/>
            </Col>
          </Row>
        </Container>
      </section>

      <SignUpComponent />     
    </Layout>
  )
}

export default EventHackathonPage